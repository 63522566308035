import React, { Component } from "react";
import { Link } from "react-router-dom";
import LargeImage from "../Common/LargeImage";

export default class CompareOverview extends Component {
  render() {
    return (
      <div>
        <div className="bg-primary  text-white text-center p-2">
          <div className="my-5 py-5">
            <h1 className="px-2 my-4">Schema Comparison and Diffing</h1>

            <h4 className="content-width mx-auto font-weight-normal p-3">
              Grid-ML simplifies the process of comparing and analyzing the
              differences between schemas. It shows you all the differences and
              allows you to filter by name, description, or type of change.
              Grid-ML saves you time and provides invaluable insights to help
              you plan schema version upgrades and to confidently deploy
              changes.
            </h4>
            <div className="row mx-auto py-4">
              <button
                className="mx-auto btn btn-lg btn-danger mt-2"
                color="danger"
              >
                <Link
                  to="/xsd-diff-tool"
                  href="/xsd-diff-tool"
                  className="text-white font-weight-bold"
                >
                  Learn more about schema comparison
                </Link>
              </button>
            </div>
            <LargeImage
              alt="XML Schema Comparison and Diffing"
              name="compare-overview"
            />
          </div>
        </div>
      </div>
    );
  }
}
