import React, { Component } from "react";
import { Link } from "react-router-dom";
import LargeImage from "../Common/LargeImage";

export default class SearchOverview extends Component {
  render() {
    return (
      <div>
        <div className="bg-dark  text-white text-center p-2">
          <div className="my-5 py-5">
            <h1 className="px-2 my-4">Schema Search</h1>
            <h4 className="content-width mx-auto font-weight-normal p-3">
              Grid-ML helps you search across multiple schemas and thousands of
              data points with ease. It provides instantaneous search results
              for data names, schema comments, namespaces, data types, and
              enumeration values. Grid-ML shows the results in an uncluttered,
              hierarchical view that allows you to quickly find the relevant
              items.
            </h4>

            <div className="row mx-auto py-4">
              <button
                className="mx-auto btn btn-lg btn-danger mt-2"
                color="danger"
              >
                <Link
                  to="/xsd-visualizer"
                  href="/xsd-visualizer"
                  className="text-white  font-weight-bold"
                >
                  Learn more about schema search
                </Link>
              </button>
            </div>

            <LargeImage alt="MISMO Model Viewer" name="search-overview" />
          </div>
        </div>
      </div>
    );
  }
}
