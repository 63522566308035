import React, { Component } from "react";
// import OptimizedImage from "../Common/OptimizedImage";
// import UploadSchemaPng from "./img/upload-schema/upload-schema.png";
// import UploadSchemaWebp from "./img/upload-schema/upload-schema.webp";
// import SelectRootsPng from "./img/select-roots/select-roots.png";
// import SelectRootsWebp from "./img/select-roots/select-roots.webp";
// import SearchForPng from "./img/search-for/search-for.png";
// import SearchForWebp from "./img/search-for/search-for.webp";

export default class Steps extends Component {
  getStepContent(number, caption, text) {
    return (
      <div className="col-sm-12 col-md-12 col-lg-4 my-3 ">
        <div className="circle2 align-self-center  bg-primary  text-white">
          <div className="mt-2 d-inline-block">
            <p className="marketing-text m-0 p-0 mt-3">Step</p>
            <p className=" m-0 p-0">{number}</p>
          </div>
        </div>
        <h5 className="my-4 text-center text-black font-weight-bold">
          {caption}
        </h5>
        {/* <div className="pt-3" style={{ height: "134px" }}>
          <OptimizedImage png={png} webp={webp} alt={caption} />
        </div> */}
        <div className=" ">
          <p className="marketing-text m-3 text-center pb-5">{text}</p>
        </div>
      </div>
    );
  }
  render() {
    return (
      <div className="bg-white p-5 ">
        <div className="text-center mx-auto content-widths py-5 px-md-5 px-sm-1">
          <h2 className="mb-5 font-weight-bold ">How it works</h2>

          <div className="row  ">
            {this.getStepContent(
              1,
              "Choose a schema",
              "Upload your own files, select a schema from a shared repository, or use one of the pre-loaded public schemas. Grid-ML supports standard and proprietary schemas in both W3C and OpenAPI formats."
              //   UploadSchemaPng,
              //  UploadSchemaWebp
            )}
            {this.getStepContent(
              2,
              "Select a root node",
              "Select the root node you need for your project. Grid-ML allows you to search for all available global elements across your entire repository."
              //  SelectRootsPng,
              //  SelectRootsWebp
            )}
            {this.getStepContent(
              3,
              "Start exploring",
              "Drill down into the data structure, search for business terms, compare schemas side-by-side, and validate paths, all at lighting fast speed."
              //  SearchForPng,
              // SearchForWebp
            )}
          </div>
          <h4 className="m-3">Rinse and repeat</h4>
          <p className="marketing-text px-2">
            Set up as many schemas and root nodes as you need and feel free to
            modify your settings at any time. Grid-ML remembers your
            configuration when you log back in, so you can continue your work
            from where you left it off. You can also organize schemas into
            repositories and share them with team members and external
            contributors.
          </p>
        </div>
      </div>
    );
  }
}
