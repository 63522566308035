import React, { Component } from "react";

import Footer from "../Footer/Footer";
import HeaderPublic from "../Header/HeaderPublic";
import UseCases from "./UseCases";
import Benefits from "./Benefits";

import Steps from "./Steps";
//import Problem from "./Problem";
import CallToAction from "../Common/CallToAction";

import SearchOverview from "./SearchOverview";
import ViewerOverview from "./ViewerOverview";
import CompareOverview from "./CompareOverview";

export default class Home extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="app">
        <HeaderPublic />
        <div className="app-body  ">
          <main className="main justify-content-center">
            <div>
              <CallToAction />
              {/* <Problem /> */}
              <ViewerOverview />
              <SearchOverview />
              <CompareOverview />
              <Steps />
              <UseCases />
              <Benefits />
              {/* <Comparison /> */}
              <CallToAction />
            </div>
          </main>
        </div>
        <Footer />
      </div>
    );
  }
}
