import React, { Component } from "react";
import { Link } from "react-router-dom";
import LargeImage from "../Common/LargeImage";

export default class ViewerOverview extends Component {
  render() {
    return (
      <div>
        <div className=" bg-light  text-dark text-center p-5">
          <div className="my-5">
            <h1 className="p-3 ">Schema Viewer</h1>
            <h4 className="content-width mx-auto font-weight-normal p-3">
              Grid-ML is designed to provide easy navigation through any data
              structure, no matter how deep or complex. The exclusive columnar
              view shows all the information you need, but nothing more. No
              fancy graphics to get in your way.
            </h4>
            <div className="row mx-auto py-4">
              <button
                className="mx-auto btn btn-lg btn-danger mt-2"
                color="danger"
              >
                <Link
                  to="/xsd-viewer"
                  href="/xsd-viewer"
                  className="text-white  font-weight-bold"
                >
                  Learn more about schema visualization
                </Link>
              </button>
            </div>
            <LargeImage alt="MISMO Model Viewer" name="viewer-overview" />
          </div>
        </div>
      </div>
    );
  }
}
