import React, { Component } from "react";

import { Icon } from "@iconify/react";
import SearchIcon from "@iconify/icons-mdi/magnify";
import SpecIcon from "@iconify/icons-mdi/file-document-edit-outline";
import RulesIcon from "@iconify/icons-mdi/bank";
import CompareIcon from "@iconify/icons-mdi/format-columns";
import HubspotIcon from "@iconify/icons-mdi/hubspot";

export default class UseCases extends Component {
  render() {
    const style = {
      fontSize: "4rem",
    };
    return (
      <div className="row centered p-5 align-top bg-dark text-white">
        <div className="my-5">
          <div className="row mx-auto">
            <h2 className="mb-3 mx-auto font-weight-bold ">Use Cases</h2>
            <div className="row w-100 m-0 justify-content-center">
              <div className=" p-5 col-xl-2 col-lg-4 col-md-6 col-sm-12">
                <Icon
                  style={style}
                  icon={SearchIcon}
                  className="row mx-auto text-primary"
                />
                <p className="display-block text-center row marketing-text p-2">
                  Search and visualize data models.
                </p>
              </div>
              <div className=" p-5 col-xl-2 col-lg-4 col-md-6 col-sm-12">
                <Icon
                  style={style}
                  icon={SpecIcon}
                  className="row mx-auto  text-primary"
                />
                <p className="display-block text-center row marketing-text p-2">
                  Create specifications for mapping data between industry
                  standards and proprietary schemas.
                </p>
              </div>

              <div className=" p-5 col-xl-2 col-lg-4 col-md-6 col-sm-12">
                <Icon
                  style={style}
                  icon={RulesIcon}
                  className="row mx-auto text-primary"
                />
                <p className="display-block text-center row marketing-text p-2">
                  Create specifications for data validation and business rules.
                </p>
              </div>
              <div className=" p-5 col-xl-2 col-lg-4 col-md-6 col-sm-12">
                <Icon
                  style={style}
                  icon={CompareIcon}
                  className="row mx-auto text-primary"
                />
                <p className="display-block text-center row marketing-text p-2">
                  Compare different versions of data standards and reference
                  models.
                </p>
              </div>
              <div className=" p-5 col-xl-2 col-lg-4 col-md-6 col-sm-12">
                <Icon
                  style={style}
                  icon={HubspotIcon}
                  className="row mx-auto text-primary"
                />
                <p className="display-block text-center row marketing-text p-2">
                  Create centralized repositories and share schemas with team
                  members and external contributors.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
